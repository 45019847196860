import React, { ChangeEvent } from "react"

import styled from "styled-components"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import { FormInputState } from "../../utils/validations"

type InputProps = TextFieldProps & {
  borderColor?: string
  textColor?: string
  minLength?: number
  maxLength?: number
  inputHandler?: React.FormEventHandler<HTMLDivElement>
  rows?: number
  autoComplete?: string
}

const StyledInput = styled(TextField)<{
  bordercolor?: string
  textcolor?: string
}>`
  & input {
    color: ${({ textcolor }) => textcolor};
    background-color: var(--color-white);
  }
  & label {
    font-size: 12px;
  }
  & label.Mui-focused {
    color: var(--color-teal);
  }
  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: ${({ bordercolor }) => bordercolor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ bordercolor }) => bordercolor};
  }
  &.Mui-focused fieldset {
    border-color: ${({ bordercolor }) => bordercolor};
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ bordercolor }) => bordercolor};
    }
    &.Mui-focused fieldset {
      border-color: var(--color-teal);
    }
    &:hover fieldset {
      border-color: var(--color-teal);
    }
  }
`

export const FormInput = ({
  fieldName,
  fieldId,
  fieldType,
  inputs,
  inputHandler,
  autoFocus,
  handleFocus,
  fullWidth,
  multiline = false,
  rows,
  autoComplete,
  disabled = false,
}: {
  inputs: Record<string, FormInputState>
  fieldName: string
  fieldType?: string
  fieldId: string
  autoFocus?: boolean
  fullWidth?: boolean
  inputHandler: (
    e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => void
  handleFocus?: (
    e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => void
  multiline?: boolean
  rows?: number
  autoComplete?: string
  disabled?: boolean
}) => {
  const field = inputs[fieldName] as FormInputState
  return (
    <Input
      id={fieldId}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      value={field.value}
      label={field.label}
      name={fieldName}
      type={fieldType}
      variant="outlined"
      required={field.validation?.some(e => e.type === "required")}
      onBlur={inputHandler}
      inputHandler={inputHandler}
      error={!!field.error}
      helperText={field.error}
      onFocus={handleFocus}
      multiline={multiline}
      rows={rows}
      autoComplete={autoComplete}
      disabled={disabled}
    />
  )
}

export const Input = ({
  name,
  type,
  textColor = "var(--color-grey-4)",
  borderColor = "var(--color-grey-3)",
  label,
  variant,
  required,
  fullWidth,
  autoFocus,
  minLength,
  maxLength,
  inputHandler,
  multiline,
  value,
  autoComplete,
  id,
  onChange,
  helperText,
  className,
  size,
  error,
  onBlur,
  disabled,
  onFocus,
  rows,
}: InputProps) => {
  return (
    <>
      {label && (
        <StyledInput
          name={name}
          bordercolor={borderColor}
          textcolor={textColor}
          type={type}
          variant={variant}
          required={required}
          fullWidth={fullWidth}
          multiline={multiline}
          label={label}
          autoFocus={autoFocus}
          onInput={inputHandler}
          autoComplete={autoComplete}
          value={value}
          id={id}
          onChange={onChange}
          helperText={helperText}
          className={className}
          size={size}
          inputProps={{ minLength: minLength, maxLength: maxLength }}
          error={error}
          onBlur={onBlur}
          disabled={disabled}
          onFocus={onFocus}
          minRows={rows}
        />
      )}
    </>
  )
}

export default Input
